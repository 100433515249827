import CrudService from "@/services/shared/http/CrudService";

export default class AdminService extends CrudService {
  constructor(params = {}) {
    super("user", params);
  }
  index(params = {}) {
    return this.api.GET("user/get-all-admin", params)
  }
  create(data, params = {}) {
    return this.api.POST('auth/create-admin', data, params)
  }
  update(id, data, params = {}) {
    return this.api.POST('user/' + id, data, params)
  }
}
