var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"adminForm",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"adminInfo",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Name *","label-for":"Name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Name"},model:{value:(_vm.value.name),callback:function ($$v) {_vm.$set(_vm.value, "name", $$v)},expression:"value.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Email *","label-for":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Email","type":"email"},model:{value:(_vm.value.email),callback:function ($$v) {_vm.$set(_vm.value, "email", $$v)},expression:"value.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(!this.value._id)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Password *","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"password","placeholder":"password"},model:{value:(_vm.value.password),callback:function ($$v) {_vm.$set(_vm.value, "password", $$v)},expression:"value.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,192063062)})],1)],1):_vm._e(),(!this.value._id)?_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label-for":"password-confirm","label":"Confirm Password *"}},[_c('validation-provider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"reset-password-confirm","type":_vm.password2FieldType,"state":errors.length > 0 ? false : null,"name":"reset-password-confirm","placeholder":"Confirm password"},model:{value:(_vm.value.cPassword),callback:function ($$v) {_vm.$set(_vm.value, "cPassword", $$v)},expression:"value.cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.password2ToggleIcon},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1608134933)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Role *","label-for":"Role"}},[_c('validation-provider',{attrs:{"name":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"custom-select-style",class:errors.length > 0 ? 'border-danger-for-v-select' : '',attrs:{"id":"Role","options":_vm.roleOptions,"placeholder":"Select role","reduce":function (option) { return option.value; },"label":"label"},model:{value:(_vm.value.role),callback:function ($$v) {_vm.$set(_vm.value, "role", $$v)},expression:"value.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary","disabled":_vm.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.loading ? 'Saving...' : 'Save'))]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"id":"cancel-btn","variant":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }