<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card>
      <form
        ref="adminForm"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="adminInfo" tag="form">
          <b-row>
            <b-col md="12">
              <b-form-group label="Name *" label-for="Name">
                <validation-provider
                  #default="{ errors }"
                  name="Name"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.name"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Email *" label-for="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    v-model="value.email"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Email"
                    type="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!this.value._id" md="12">
              <!-- password -->
              <b-form-group label="Password *" label-for="password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="Password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="value.password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="password"
                      placeholder="password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col v-if="!this.value._id" md="12">
              <b-form-group
                label-for="password-confirm"
                label="Confirm Password *"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:Password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="value.cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="Confirm password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Role *" label-for="Role">
                <validation-provider #default="{ errors }" name="Role">
                  <v-select
                    id="Role"
                    class="custom-select-style"
                    :class="
                      errors.length > 0 ? 'border-danger-for-v-select' : ''
                    "
                    v-model="value.role"
                    :options="roleOptions"
                    placeholder="Select role"
                    :reduce="(option) => option.value"
                    label="label"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="submit"
          class="float-right"
          variant="primary"
          :disabled="loading"
          >{{loading ? 'Saving...' : 'Save'}}</b-button
        >

        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </form>
    </b-card>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import SharedService from "@/services/SharedService.js";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
  },
  props: ["value", "action"],
  mixins: [togglePasswordVisibility],
  data() {
    return {
      sharedService: new SharedService(),
      loading: false,
      formData: null,
      required,
      languageLoader: true,
      topicLoader: true,
      languagesList: [],
      topicsList: [],
      isPreview: false,
      media: null,
      media1: null,
      // Toggle Password
      passwordFieldType: "password",
      password2FieldType: "password",
      roleOptions: [
        { label: "Super admin", value: "admin" },
        { label: "External event manager", value: "eventManager" },
      ],
    };
  },
  mounted() {
  },
  watch: {},
  methods: {
    submit() {
      this.$refs.adminInfo.validate().then((success) => {
        if (success) {
          this.loading = true;
          const formData = new FormData();
          formData.append("name", this.value.name);
          formData.append("email", this.value.email);
          if(!this.value._id) formData.append("password", this.value.password);
          if(!this.value._id) formData.append("confirmPassword", this.value.cPassword);
          if(this.value._id) formData.append("id", this.value._id);
          if(this.value._id) formData.append("providerType", this.value.providerType)
          formData.append("role", this.value.role);
          this.$emit("formSubmitted", formData);
        } else {
        }
      });
    },
    close() {
      this.$router.push("/admin-management");
    },
    togglePasswordVisibility() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.media = e.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.media1 = e.target.result;
        this.isPreview = true;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
};
</script>
<style>
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 1.357rem;
  object-fit: cover;
  cursor: pointer;
  border: groove;
}
</style>
