<template>
  <div class="">
    <AdminForm ref="form" action="Update" v-model="formData" @formSubmitted="updateAdmin" />
  </div>
</template>
  
  <script>
import AdminForm from "@/views/components/admin-manangement/AdminForm";
import AdminService from "@/services/AdminService";

export default {
  name: "updateAdmin",
  components: {
    AdminForm
  },
  data() {
    return {
      adminService: new AdminService(),
      id: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateAdmin(e) {
      this.$refs.form.loading = true;
      this.adminService.update(this.formData._id, e).then(res => {
        this.$router.push('/admin-management')
      }).catch(err => {
        console.log(err);
       }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getAdmin() {
      this.adminService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
        this.formData.topics = this.formData.topics.map(e => e._id);
        this.formData.languages = this.formData.languages.map(e => e._id);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getAdmin()
  }
}
</script>
  
  <style scoped>
  </style>
  